import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <footer className="footer widget-footer clearfix">
        {this.props.children}
        <div className="bg-img-address first-footer ttm-bg ttm-bgimage-yes">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row align-items-md-center">
              <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
                <div className="footer-logo text-sm-center">
                  <img
                    data-src="images/logo.svg"
                    className="img-fluid"
                    alt="footer-logo"
                    src="images/logo.svg"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
                <div className="widget widget_text  clearfix">
                  <h3 className="widget-title">Note from the Caimers</h3>
                  <div className="textwidget widget-text">
                    Cybersecurity is the foundation of trust in a connected world.
                    <br />
                    Let Virtual Caim help you build that trust.
                  </div>
                </div>
                {/* <div className="text-left">
                                    <div className="featured-icon-box left-icon icon-align-top">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                                                <i className="ti ti-location-pin" />
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-desc">
                                                <p>AHMEDABAD, GUJARAT, INDIA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                <div className="text-sm-center">
                  <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border text-transform-lower">
                    <h6>
                      <i className="far fa-envelope" />
                      audit{"<at>"}virtualcaim{"<dot>"}com
                    </h6>
                  </a>
                </div>
                <div className="text-sm-center widget flicker_widget clearfix">
                  <h5>Follow Us On</h5>
                  <div className="social-icons circle social-hover">
                    <ul className="list-inline">
                      <li className="social-github">
                        <a
                          href="https://github.com/VCAudits"
                          className="tooltip-top rounded-circle"
                          target="_blank"
                          data-tooltip="Github"
                        >
                          <i className="fab fa-github" />
                        </a>
                      </li>
                      <li className="social-twitter">
                        <a
                          href="https://twitter.com/VirtualCaim"
                          className="tooltip-top rounded-circle"
                          target="_blank"
                          data-tooltip="Twitter"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li className="social-instagram">
                        <a
                          href="https://instagram.com/virtualcaimsecurity?utm_medium=copy_link"
                          className=" tooltip-top rounded-circle"
                          target="_blank"
                          data-tooltip="Instagram"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li className="social-linkedin">
                        <a
                          href="https://www.linkedin.com/company/virtual-caim"
                          className=" tooltip-top rounded-circle"
                          target="_blank"
                          data-tooltip="LinkedIn"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-img-footer second-footer ttm-textcolor-white">
          <div className="container-xl">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area"></div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 offset-lg-1 widget-area res-767-mb-40"></div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
          <div className="container-xl">
            <div className="row copyright align-items-center res-767-text-center">
              <div className="col-md-6">
                <div>
                  <span>Copyright © 2025&nbsp; by VIRTUAL CAIM</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-md-right res-767-mt-10">
                  <div className="d-lg-inline-flex">
                    <ul id="menu-footer-menu" className="footer-nav-menu">
                      <li>
                        <Link to="/terms-and-conditions" target="_blank">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy" target="_blank">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                    {/* <div className="float-md-right ml-20 res-767-ml-0">
                                            <img data-src="images/footer-payment-img.png" alt="payment-img" src="images/footer-payment-img.png" />
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
